
import { msalInstance, loginRequest } from '@/config/msal'
import { InteractionType, InteractionRequiredAuthError } from '@azure/msal-browser'
import { callMsGraph } from '@/utils/MsGraphApiCall'
import store from '@/store/index'
import ParametersService from '@/services/01Cell/ParametersService'
// Hash problem https://github.com/AzureAD/microsoft-authentication-library-for-js/pull/4415

export function registerGuard (router) {
    router.beforeEach(async (to, from, next) => {
        // Si la page n'est pas public
        if (to.meta.requiresAuth) {
            const request = {
                ...loginRequest,
                redirectStartPage: to.fullPath,
            }
            const shouldProceed = await isAuthenticated(msalInstance, InteractionType.Redirect, request)
            if (shouldProceed) {
                // Load parameters
                await loadParameters(next)
                // CheckRole
                checkRole(to.meta.roles, msalInstance, to, next)
            } else {
              // On enlève les infos de l'utilisateur
              store.commit('resetUser')
              next('/login')
            }
        } else {
          // La page est public
          // Si la page est 'Login', on vérifie si l'utilisateur est authentifié
          if (to.name === 'Login') {
            const isAuthenticated = await checkIfAuthenticated(msalInstance)
            // Si l'utilisateur est authentifié, on retourne à la page d'acceuil
            if (isAuthenticated) {
              // Load parameters
              await loadParameters(next)
              // Go to Portal
              next('/my_portal')
            } else {
              // On enlève les infos de l'utilisateur
              store.commit('resetUser')
            }
          }
          next()
        }
    })
}

export async function isAuthenticated (instance, interactionType, loginRequest) {
    // If your application uses redirects for interaction, handleRedirectPromise must be called and awaited on each page load before determining if a user is signed in or not
    return instance.handleRedirectPromise().then(async () => {
        const accounts = instance.getAllAccounts()
        if (accounts.length > 0) {
          // Mise à jour des infos de l'utilisateur
          await updateUserInfo(accounts[0])
          // Get token silenttly
          try {
              await instance.acquireTokenSilent({
                  ...loginRequest,
                  accounts,
              })
              return true
          } catch (err) {
              // Si on ne réussi pas à récupérer le token, il faut s'authentifier
              if (err instanceof InteractionRequiredAuthError) {
                  // fallback to interaction when silent call fails
                  return instance.acquireTokenRedirect(loginRequest).catch((err) => {
                    console.error(err)
                    return false
                  })
              }
          }
        }

        // User is not signed in and attempting to access protected route. Sign them in.
        if (interactionType === InteractionType.Popup) {
            return instance.loginPopup(loginRequest).then(() => {
                return true
            }).catch((err) => {
                console.error(err)
                return false
            })
        } else if (interactionType === InteractionType.Redirect) {
            return instance.loginRedirect(loginRequest).then(() => {
                return true
            }).catch((err) => {
                console.error(err)
                return false
            })
        }

        return false
    }).catch(() => {
        return false
    })
}

function checkIfAuthenticated (instance) {
    // If your application uses redirects for interaction, handleRedirectPromise must be called and awaited on each page load before determining if a user is signed in or not
    return instance.handleRedirectPromise().then(async () => {
        const accounts = instance.getAllAccounts()
        if (accounts.length > 0) {
            // Mise à jour des infos de l'utilisateur
            await updateUserInfo(accounts[0])
            return true
        }

        return false
    }).catch(() => {
        return false
    })
}

function checkRole (roles, instance, to, next) {
    // Si le rôle est user. L'utilisateur peut y aller
    if (roles.some(item => item === 'user')) return next()

    // Récupération des rôles de l'utilisateur
    const accounts = instance.getAllAccounts()
    const found = accounts[0].idTokenClaims.roles.some(r => roles.indexOf(r) >= 0)
    // Role not find
    if (!found) {
        // Go to unauthorized page. If if to not loop redirecting
        if (to.path !== '/unauthorized') {
            next('/unauthorized')
        }
    } else {
        next()
    }
}

async function updateUserInfo (account) {
    // Si le username est différent, on le mets à jour synchrone
    if (store.state.userInfo.info.username !== account.username) {
        const response = await callMsGraph()
        const userInfo = {
            name: response.surname,
            firstName: response.givenName,
            mail: response.mail,
            displayName: account.name,
            username: account.username,
            roles: account.idTokenClaims.roles,
          }
          store.commit('updateUser', userInfo)
    } else {
        // Mets à jour asynchrone
        callMsGraph().then(response => {
          const userInfo = {
            name: response.surname,
            firstName: response.givenName,
            mail: response.mail,
            displayName: account.name,
            username: account.username,
            roles: account.idTokenClaims.roles,
          }
          store.commit('updateUser', userInfo)
        })
    }
    // console.log(store.state.userInfo.info.username)
    // console.log(account)
    // // On mets à jour les info de l'utilisateur
    // // À cause que la function 'callMsGraph' qui demande un await, pour ne pas perdre de temps je mets quelques info pour l'utilisateur
    // const userInfo = {
    //   name: null,
    //   firstName: null,
    //   mail: null,
    //   displayName: account.name,
    //   username: account.username,
    //   roles: account.idTokenClaims.roles,
    // }
    // store.commit('updateUser', userInfo)

    // // Je mets plus d'info grâce à la fonction 'callMsGraph'
    // callMsGraph().then(response => {
    //   const userInfo = {
    //     name: response.surname,
    //     firstName: response.givenName,
    //     mail: response.mail,
    //     displayName: account.name,
    //     username: account.username,
    //     roles: account.idTokenClaims.roles,
    //   }
    //   store.commit('updateUser', userInfo)
    // })
}

async function loadParameters (next) {
  try {
    // On regarde si les paramètres sont déjà là. Si il n'y sont pas ont l'ai récupère
    if (!store.state.parameters.parameters.length) {
        // Liste des paramètres que l'on mets dans le vuex pour récupéré partout dans le code
        const parameters = await ParametersService.listAll()
        store.commit('parameters/setParameters', parameters)
    }
  } catch (error) {
    console.log(error)
    next('/error500')
  }
}
